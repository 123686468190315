<template>
  <div>
    <h3 class="title">Procure e selecione o terapeuta desejado</h3>
    <v-text-field
      v-model="filter"
      class="mt-3"
      label="Busque por nome, cidade, estado ou bairro"
      outlined
      hide-details
      :loading="loading"
      loader-height="5"
      @input="(value) => inputFilter(value)"
    />
    <div class="my-5">
      <v-virtual-scroll
        v-if="hasTherapists"
        :items="therapists"
        :item-height="100"
        :height="500"
        class="items-border-bottom-grey"
      >
        <template v-if="hasTherapists" #default="{ item }">
          <v-list-item two-line @click="selectTherapist(item)">
            <v-list-item-avatar>
              <v-icon x-large>account_circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.therapistName }}
              </v-list-item-title>
              <v-list-item-subtitle class="localization-label">
                Bairro: {{ item.neighborhood }} - {{ item.city }} -
                {{ item.state }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span v-if="item.sessionValue" class="item-price">
                  R${{ item.sessionValue }}</span
                >
                <span v-if="!item.sessionValue" class="item-price">
                  R$ 0,00</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
      <template v-else>
        <empty-list text="Nenhum terapeuta encontrado!" />
      </template>
    </div>
    <stepper-buttons />
  </div>
</template>

<script>
import {
  filterToAppointment,
  findFirstTenTherapists,
} from "@/services/external/therapists-service";
import { debounce } from "lodash";

export default {
  name: "StepTherapist",
  components: {
    StepperButtons: () =>
      import(
        "@/domains/appointments/patient/presentation/components/stepper/StepperButtons"
      ),
  },
  data: () => ({
    filter: "",
    therapists: [],
    firstTherapists: [],
    loading: false,
  }),
  computed: {
    hasTherapists() {
      return this.therapists && this.therapists.length > 0;
    },
  },
  created() {
    this.fillTherapists();
    this.debounceEmit = debounce((value) => {
      this.filterBy(value);
      this.loading = false;
    }, 500);
  },
  methods: {
    inputFilter(inputValue) {
      this.loading = true;
      this.debounceEmit(inputValue);
    },
    async fillTherapists() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findFirstTenTherapists();
        this.therapists = data;
        this.firstTherapists = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async filterBy(inputValue) {
      try {
        if (!inputValue) {
          this.therapists = this.firstTherapists;
          return;
        }
        this.loading = true;
        const { data } = await filterToAppointment(inputValue);
        console.log(data);
        this.therapists = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.loading = false;
      }
    },
    selectTherapist(therapistDto) {
      this.$store.dispatch(
        "appointments/patient/selectTherapist",
        therapistDto
      );
    },
  },
};
</script>

<style scoped>
.item-price {
  font-size: 14px;
}
.localization-label {
  font-size: 13px;
}
</style>
